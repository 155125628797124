*,
body,
h1,
h2,
h3,
div,
section,
p,
ul,
li {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

address {
  font-style: normal;
}

/* common */
@import url("https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

html {
  font-family: "Open Sans", sans-serif;
  font-size: 11.6px;
  font-weight: 300;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background: white;
  color: black;

  @media (prefers-color-scheme: dark) {
    background: rgb(20, 20, 20);
    color: white;
  }
}

h1,
h2,
h3,
h4 {
  font-family: "Kaisei Tokumin", serif;
}

// variables
$width: 90vw;
$max-width: 1120px;

#container {
  width: $width;
  max-width: $max-width;
  margin: 0 auto;
}

// global effect
.hover-button {
  background: linear-gradient(
      90deg,
      transparent calc(100% / 3),
      currentColor 0 calc(300% / 3),
      transparent 0
    )
    var(--d, 100%) 100% / 200% 1px repeat-x;
  cursor: pointer;

  &:hover {
    --d: -100%;
    transition: 0.7s;
  }
}
